/* You can add global styles to this file, and also import other style files */
@use 'material-icons/iconfont/material-icons.css';
@use '/src/utils//style-class.scss';

* {
    font-family: 'Inter';
}

body {
    margin: 0;
    // height: 100%;
    //   width: 100%;
    //  box-sizing: border-box;

    @media screen and (max-width:1024) {
        height: 100dvh;
    }
}

.albi-dialog-mask .albi-dialog-card .albi-dialog-card-content {
    padding: 2px;
}

*::first-letter {
    text-transform: uppercase;
}

.mat-icon::first-letter {
    text-transform: none;
}

.lowercase,
*.lowercase::first-letter {
    text-transform: lowercase;
}

.lowercase-first-letter::first-letter {
    text-transform: lowercase;
}

input {
    font-weight: 100;
}

p {
    font-weight: 500;
    margin: 0;
}

hr {
    width: 100%;
    border-top: thin solid var(--borders-color);
}

.border-card {
    display: grid;
    background-color: var(--secondary-background-color);
    border: 1px solid var(--borders-color);
    border-radius: var(--default-element-radius);
    padding: 24px;
    gap: 24px;

    &.ivory-bg {
        background-color: #fffaf2;
    }

    &.aliceblue-bg {
        background-color: #F2F9FF;
    }

}

.disabled-label {
    color: #888888;
}

#sentry-feedback {
    --accent-background: #ff0050;
    //--inset: auto auto 120px 0px;
}

.avatar-image {
    clip-path: circle();
    object-fit: cover;

    &.small {
        height: 30px;
        width: 30px;
    }

    &.medium {
        height: 80px;
        width: 80px;
    }

    &.large {
        height: 120px;
        width: 120px;
    }

    &.contain {
        object-fit: contain;
    }
}

@keyframes slideInFromAbove {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.divider {
    width: 100%;
    border-top: 0.5px solid var(--borders-color);
    opacity: 0.65;
}

.loading-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1234;
    background-color: #c6c6c650;
    display: flex;
    justify-content: center;
    align-items: center;
}

.message-panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    pointer-events: none;

    .message-column {
        display: flex;
        flex-direction: column;

        .overlay-message {
            z-index: 1002;
            height: fit-content;
            padding: 1rem;
            border-radius: 10px;
            margin: 1rem 0;
            animation: slideInFromAbove 0.5s ease forwards;

            &--danger {
                background: linear-gradient(90deg, #ff3d32 8px, #ffd0ce 8px);
                color: #ff3d32;
            }

            &--warning {
                background: linear-gradient(90deg, #c79807 8px, #faedc4 8px);
                color: #c79807;
            }

            &--success {
                background: linear-gradient(90deg, #22c55e 8px, #caf1d8 8px);
                color: #1da750;
            }

        }

    }

}

.header-message {
    padding: 0.5rem;
    text-align: center;
    animation: slideInFromAbove 0.5s ease forwards;

    &--danger {
        background-color: var(--color-ruby);
        color: white;
    }

    &--warning {
        background-color: var(--color-orange);
        color: white;
    }

    &--success {
        background-color: var(--color-eco-dark);
        color: white;
    }

}

.no-scroll {
    overflow: hidden;
}

a {
    text-decoration: none;
}

//to be moved to library

.secondary-text-color {
    color: var(--icon-text-color);
}

.flex-2 {
    flex: 2;
}


.item-selected {
    outline: thin solid var(--color-ruby)
}

//mobile-grid-three-col
.grid-template-3-col {
    grid-template-columns: repeat(3, 1fr);

    @media screen and (max-width:1024px) {
        grid-template-columns: repeat(2, 1fr);

    }

    @media screen and (max-width:768px) {
        grid-template-columns: repeat(1, 1fr);

    }
}



albi-table {
    th {
        min-width: 80px;
    }

    th.frozen-column {
        min-width: 0;
    }
}