.no-padding {
    padding: 0;
}

.no-margin {
    margin: 0;
}

.p-1 {
    padding: 0.5rem;
}

.p-3 {
    padding: 1rem;
}

.p-6 {
    padding: 2rem;
}

.p-24 {
    padding: 24px;
}

.p-48 {
    padding: 48px;
}

.px-3 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.pr-1 {
    padding-right: 0.5rem;
}

.pr-3 {
    padding-right: 1rem;
}

.pr-6 {
    padding-right: 2rem;
}

.m-y-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.m-y-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.m-y-6 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.my-1 {
    margin: 0.5rem 0;
}

.mb-12 {
    margin-bottom: 12px;
}

.mb-24 {
    margin-bottom: 24px;
}

.pb-12 {
    margin-bottom: 12px;
}

.pb-24 {
    margin-bottom: 24px;
}

.d-flex {
    display: flex;
}


.grid-two-col {
    grid-template-columns: 1fr 1fr;
}

.grid-three-col {
    grid-template-columns: 1fr 1fr 1fr;
}

.d-grid {
    display: grid;

    @media screen and (max-width:600px) {
        grid-template-columns: 1fr;
    }
}

.d-block {
    display: block;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: end;
}

.align-start {
    align-items: start;
}

.align-self-center {
    align-self: center;
}



.w-full {
    width: 100%;
}

.h-full {
    height: 100%;
}

.w-half {
    width: 50%;
}

.j-end {
    justify-content: end;
}

.j-center {
    justify-content: center;
}

.j-between {
    justify-content: space-between;
}

.j-around {
    justify-content: space-around;
}

.j-self-center {
    justify-self: center;
}

.j-self-end {
    justify-self: end;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-column {
    flex-direction: column;
}

.text-start {
    text-align: start;
}

.text-center {
    text-align: center;
}

.icon-pointer {
    albi-icon {
        cursor: pointer;
    }
}

.cliccable {
    cursor: pointer;
}

.flex-1 {
    flex: 1;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.capitalize {
    text-transform: capitalize;
}

.font-small {
    font-size: 12px;
}

.clip-circle {
    clip-path: circle();
}

.gap-1 {
    gap: 0.5rem;
}

.gap-3 {
    gap: 1rem;
}

.gap-6 {
    gap: 2rem;
}

.gap-4 {
    gap: 4px;
}

.gap-12 {
    gap: 12px;
}

.gap-24 {
    gap: 24px;
}

.gap-48 {
    gap: 48px;
}

.font-w-100 {
    font-weight: 100;
}

.font-w-200 {
    font-weight: 200;
}

.font-w-400 {
    font-weight: 400;
}

.font-w-600 {
    font-weight: 600;
}

.font-ruby {
    color: var(--color-ruby);
}

.text-underline {
    text-decoration: underline;
}

.light-ruby-bg {
    background-color: var(--color-ruby-light);
}

.border-box {
    box-sizing: border-box;
}

.default-border-radius {
    border-radius: var(--default-element-radius);
}

.secondary-background-color {
    background-color: var(--secondary-background-color);
}

.border-color {
    border: thin solid var(--borders-color);
}

.pre-wrap {
    white-space: pre-wrap;
}

.pre-line {
    white-space: pre-line;
}

.break-spaces {
    white-space: break-spaces;
}

.primary-bg-color {
    background-color: var(--primary-background-color);
}

.secondary-bg-color {
    background-color: var(--secondary-background-color);
}

.relative {
    position: relative;
}

.static {
    position: static;
}

.fixed {
    position: fixed;
}

.absolute {
    position: absolute;
}

.sticky {
    position: sticky;
}

.overflow-wrap-anywhere {
    overflow-wrap: anywhere;
}



.mobile-d-flex {
    display: flex;

    @media screen and (max-width:620px) {
        flex-direction: column;
    }
}

.tablet-d-flex {
    display: flex;

    @media screen and (max-width:1020px) {
        flex-direction: column;
    }
}

.mobile-text-center {
    @media screen and (max-width:550px) {
        text-align: center;
    }
}

.mobile-grid-three-col {
    grid-template-columns: 1fr 1fr 1fr;

    @media screen and (max-width:780px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width:550px) {
        grid-template-columns: 1fr;
    }
}

.tooltip {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out;
}

.tooltip-show {
    opacity: 1;
    visibility: visible;
}

.secondary-text-color {
    color: var(--icon-text-color);
}

.font-italic {
    font-style: italic;
}